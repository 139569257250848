<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF EMPLOYEES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="employee_position_id"
            class="mx-2"
            dense
            outlined
            label="Position"
            :items="employee_position_items"
            item-value="id"
            item-text="position"
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="employee_branch_id"
            class="mx-2"
            dense
            outlined
            label="Branch/Department"
            :items="employee_branch_items"
            item-value="id"
            item-text="branch_code"
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="type_of_employee"
            class="mx-2"
            :items="[{id:1,name:'Active'},{id:2,name:'Temporary Deactivate'},{id:3,name:'Permanent Deactivated'}]"
            label="Type of Employee"
            item-value="id"
            item-text="name"
            required
            outlined
            dense
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="sorted_by"
            class="mx-2"
            :items="['Name','Birthday','Employment Date']"
            label="Sorted By"
            required
            outlined
            dense
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="status"
            class="mx-2"
            :items="['All','Trainee','Probationary','Regular']"
            label="Status"
            required
            outlined
            dense
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="duration"
            class="mx-2"
            :items="['All','1-3 months','4-11 months','1 year','2 years','3 years','4 years','5 years','6 years','7 years','8 years','9 years','10 years','11 years and above']"
            label="Length of Service"
            required
            outlined
            dense
            @change="selected_data"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers"
                        :items="personal_info"
                        disable-pagination
                        hide-default-footer
          >
            <template v-slot:top v-if="personal_info.length>0">
              <h2 v-if="!saving_data" class="mx-2">PRINT
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="print_data(personal_info,'Employee Personal Information')"
                >
                  {{icons.mdiPrinter}}
                </v-icon>
              </h2>

              <v-progress-circular
                :size=50
                :width="5"
                color="success"
                indeterminate
                v-else
              ></v-progress-circular>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ (personal_info
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.id_no }}
                </td>
                <td>
                  {{ item.last_name+','+item.first_name+' '+item.middle_name
                  }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ item.email_address }}
                </td>
                <td>
                  {{ date_format(item.date_of_birth).format('MMMM DD, YYYY') }}
                </td>
                <td>
                  {{ item.emergency_name }}
                </td>
                <td>
                  {{ item.emergency_contact_no }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers2"
                        :items="bank_info"
                        disable-pagination
                        hide-default-footer
          >
            <template v-slot:top v-if="bank_info.length>0">
              <h2 v-if="!saving_data" class="mx-2">PRINT
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="print_data(bank_info,'Employee Bank Information')"
                >
                  {{icons.mdiPrinter}}
                </v-icon>
              </h2>

              <v-progress-circular
                :size=50
                :width="5"
                color="success"
                indeterminate
                v-else
              ></v-progress-circular>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ (bank_info
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.last_name+','+item.first_name+' '+item.middle_name
                  }}
                </td>
                <td>
                  {{ item.bank_type_1
                  }}
                </td>
                <td>
                  {{ item.bank_account_1
                  }}
                </td>
                <td>
                  {{ item.bank_account_no_1
                  }}
                </td>
                <td>
                  {{ item.bank_type_2
                  }}
                </td>
                <td>
                  {{ item.bank_account_2
                  }}
                </td>
                <td>
                  {{ item.bank_account_no_2
                  }}
                </td>
                <td>
                  {{ item.bank_type_3
                  }}
                </td>
                <td>
                  {{ item.bank_account_3
                  }}
                </td>
                <td>
                  {{ item.bank_account_no_3
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers3"
                        :items="employment_info"
                        disable-pagination
                        hide-default-footer
          >
            <template v-slot:top v-if="employment_info.length>0">
              <h2 v-if="!saving_data" class="mx-2">PRINT
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="print_data(employment_info,'Employee Employment Information')"
                >
                  {{icons.mdiPrinter}}
                </v-icon>
              </h2>

              <v-progress-circular
                :size=50
                :width="5"
                color="success"
                indeterminate
                v-else
              ></v-progress-circular>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ (employment_info
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.last_name+','+item.first_name+' '+item.middle_name
                  }}
                </td>
                <td>
                  {{ item.employee_position.position
                  }}
                </td>
                <td>
                  {{ item.branch.branch_code}}
                </td>
                <td>
                  {{ date_format(item.date_of_employment).format('MMMM DD, YYYY') }}
                </td>
                <td>
                  {{ duration_counter(item.date_of_employment) }}
                </td>
                <td>
                  {{ position==='ADMIN'||position==='HUMAN RESOURCE'
                  ?formatPrice(item.compensation_rate):'' }}
                </td>
                <td>
                  {{ item.sss_no}}
                </td>
                <td>
                  {{ item.pag_ibig_no}}
                </td>
                <td>
                  {{ item.ph_no}}
                </td>
                <td>
                  {{ item.tin_no}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Name
                </th>
                <th class="text-uppercase">
                  Position
                </th>
                <th class="text-uppercase">
                  Branch/Department/Designated Area
                </th>
                <th class="text-uppercase">
                  Date of Employment
                </th>
                <th class="text-uppercase">
                  Employment Duration
                </th>
                <th class="text-uppercase">
                  Maximum of Cash Advance
                </th>
                <th class="text-uppercase">
                  Status
                </th>
                <th class="text-uppercase" v-if="id_no==='HO-2-2018'||id_no==='54545'">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in employment_info"
                :key="item.id"
              >
                <td>
                  {{ (employment_info
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.last_name+','+item.first_name+' '+item.middle_name
                  }}
                </td>
                <td>
                  {{ item.employee_position.position
                  }}
                </td>
                <td>
                  {{ item.branch.branch_code}}
                </td>
                <td>
                  {{ date_format(item.date_of_employment).format('MMMM DD, YYYY') }}
                </td>
                <td>
                  {{ duration_counter(item.date_of_employment) }}
                </td>
                <td>
                  {{
                  item.can_cash_advance.length>0?formatPrice(item.can_cash_advance[0].maximum_amount):''}}
                </td>
                <td>
                  {{ item.can_cash_advance.length>0?'ALLOWED TO CA':'NOT ALLOWED'}}
                </td>
                <td v-if="id_no==='HO-2-2018'||id_no==='54545'||id_no==='HO-38-2020'">
                  <v-icon
                    color="info"
                    @click="change_stat_cash_advance(item)"
                  >{{ icons.mdiCash100 }}
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Name
                </th>
                <th class="text-uppercase">
                  Position
                </th>
                <th class="text-uppercase">
                  Branch/Department/Designated Area
                </th>
                <th class="text-uppercase">
                  Date of Employment
                </th>
                <th class="text-uppercase">
                  Employment Duration
                </th>
                <th class="text-uppercase">
                  Total Cash Bond
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in employment_info"
                :key="item.id"
              >
                <td>
                  {{ (employment_info
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.last_name+','+item.first_name+' '+item.middle_name
                  }}
                </td>
                <td>
                  {{ item.employee_position.position
                  }}
                </td>
                <td>
                  {{ item.branch.branch_code}}
                </td>
                <td>
                  {{ date_format(item.date_of_employment).format('MMMM DD, YYYY') }}
                </td>
                <td>
                  {{ duration_counter(item.date_of_employment) }}
                </td>
                <td>
                  {{formatPrice(item.salaries_sum_deduction_cash_bond)}}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="30%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CASH ADVANCE PRIVILEGE</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="maximum_amount"
              dense
              label="Maximum Amount"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_cash_advance_previlige"> Save
        </v-btn>
        <v-btn class="mt-4 w-full" color="error" @click="is_change_salary = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiAccountCashOutline
    , mdiInformationOutline
    , mdiCash100
    , mdiPrinter
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_change_salary: false,
      saving_data: false,
      category_id: '',
      employee_position_id: '',
      category_items: [],
      employee_branch_id: '',
      employee_branch_items: [],
      personal_info: [],
      bank_info: [],
      employment_info: [],
      type_of_employee: [],
      employee_position_items: [],
      sorted_by: 'Name',
      status: 'All',
      duration: 'All',
      tab: '',
      maximum_amount: '',
      employee_id: '',
      tabs: [
        {title: 'PERSONAL INFO', icon: mdiAccountOutline},
        {title: 'BANK INFO', icon: mdiAccountCashOutline},
        {title: 'EMPLOYMENT INFO', icon: mdiInformationOutline},
        {title: 'ALLOWABLE CASH ADVANCE', icon: mdiCash100},
        {title: 'CASH BOND', icon: mdiCash100},
      ],
      headers: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'ID #', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Address', value: 'last_name', sortable: false},
        {text: 'Contact #', value: 'last_name', sortable: false},
        {text: 'Email Address', value: 'last_name', sortable: false},
        {text: 'Birth Date', value: 'last_name', sortable: false},
        {text: 'Emergency Contact', value: 'last_name', sortable: false},
        {text: 'Emergency Contact #', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Bank Type 1', value: 'last_name', sortable: false},
        {text: 'Bank Name 1', value: 'last_name', sortable: false},
        {text: 'Bank Account # 1', value: 'last_name', sortable: false},
        {text: 'Bank Type 2', value: 'last_name', sortable: false},
        {text: 'Bank Name 2', value: 'last_name', sortable: false},
        {text: 'Bank Account # 2', value: 'last_name', sortable: false},
        {text: 'Bank Type 3', value: 'last_name', sortable: false},
        {text: 'Bank Name 3', value: 'last_name', sortable: false},
        {text: 'Bank Account # 3', value: 'last_name', sortable: false},
      ],
      headers3: [
        {text: 'No.', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'last_name', sortable: false},
        {text: 'Position', value: 'last_name', sortable: false},
        {text: 'Branch/Department/Designated Area', value: 'last_name', sortable: false},
        {text: 'Date of Employment', value: 'last_name', sortable: false},
        {text: 'Employment Duration', value: 'last_name', sortable: false},
        {text: 'Compensation Rate', value: 'last_name', sortable: false},
        {text: 'SSS#', value: 'last_name', sortable: false},
        {text: 'Pag-ibig#', value: 'last_name', sortable: false},
        {text: 'PH#', value: 'last_name', sortable: false},
        {text: 'TIN#', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash100,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['position', 'id_no']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['initialize_list_of_employee', 'list_of_employee', 'register_employee_can_cash_advance']),
      initialize_data() {
        this.initialize_list_of_employee()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      duration_counter(value) {
        var a = moment();
        var b = moment(value, 'YYYY-MM-DD');

        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');
        return years + ' years ' + months + ' months ' + days + ' days'
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      change_stat_cash_advance(value) {
        this.employee_id = value.id
        this.is_change_salary = true
      },
      update_cash_advance_previlige() {
        this.register_employee_can_cash_advance({
          employee_id: this.employee_id,
          maximum_amount: this.maximum_amount,
        }).then(() => {
          this.selected_data()
        })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index >= 0) {
            this.employee_position_items = this.category_items[index].employee_position
            this.employee_position_items.splice(0, 0, {
              id: 0,
              position: 'All'
            })
            this.employee_branch_items = this.category_items[index].branch
            this.employee_branch_items.splice(0, 0, {
              id: 0,
              branch_code: 'All'
            })
            this.selected_data()
          }
        }
      },
      selected_data() {
        this.employee_id = ''
        this.is_change_salary = false
        const data = new FormData()
        data.append('sorted_by', this.sorted_by);
        data.append('employee_position_id', this.employee_position_id);
        data.append('employee_branch_id', this.employee_branch_id);
        data.append('category_id', this.category_id);
        data.append('is_active', this.type_of_employee);
        data.append('duration', this.duration);
        data.append('status', this.status);
        this.list_of_employee(data)
          .then(response => {
            this.personal_info = response.data
            this.bank_info = response.data
            this.employment_info = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      print_data(value, title) {
        this.saving_data = true
        this.alert = false

        var imgData = this.company_logo
        var payments_array = []
        var widths = []
        var info_array = []
        if (title === 'Employee Personal Information') {
          widths = [30, 50, 150, 150, 60, 100, 100, 130, 80]
          payments_array.push([
            {text: 'No.', alignment: 'left', style: 'main_info',},
            {text: 'ID #', alignment: 'left', style: 'main_info',},
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Address', alignment: 'left', style: 'main_info'},
            {text: 'Contact #', alignment: 'left', style: 'main_info'},
            {text: 'Email Address', alignment: 'left', style: 'main_info'},
            {text: 'Birth Date', alignment: 'left', style: 'main_info'},
            {text: 'Emergency Contact', alignment: 'left', style: 'main_info'},
            {text: 'Emergency Contact #', alignment: 'left', style: 'main_info'},
          ])
          value.forEach(function (item) {
            payments_array.push([
              {
                text: (value
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.id_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.last_name + ',' + item.first_name + ' ' + item.middle_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.address,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.contact_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.email_address,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: moment(item.date_of_birth).format('MMMM DD, YYYY'),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.emergency_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.emergency_contact_no,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
        } else if (title === 'Employee Bank Information') {
          widths = [30, 130, 60, 80, 80, 60, 80, 80, 60, 80, 80,]
          payments_array.push([
            {text: 'No.', alignment: 'left', style: 'main_info',},
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Bank Type 1', alignment: 'left', style: 'main_info'},
            {text: 'Bank Name 1', alignment: 'left', style: 'main_info'},
            {text: 'Bank Account # 1', alignment: 'left', style: 'main_info'},
            {text: 'Bank Type 2', alignment: 'left', style: 'main_info'},
            {text: 'Bank Name 2', alignment: 'left', style: 'main_info'},
            {text: 'Bank Account # 2', alignment: 'left', style: 'main_info'},
            {text: 'Bank Type 3', alignment: 'left', style: 'main_info'},
            {text: 'Bank Name 3', alignment: 'left', style: 'main_info'},
            {text: 'Bank Account # 3', alignment: 'left', style: 'main_info'},
          ])
          value.forEach(function (item) {
            payments_array.push([
              {
                text: (value
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.last_name + ',' + item.first_name + ' ' + item.middle_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_type_1,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_1,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_no_1,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_type_2,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_2,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_no_2,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_type_3,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_3,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_account_no_3,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
        } else if (title === 'Employee Employment Information') {
          widths = [30, 130, 60, 80, 60, 60, 80, 80, 60, 80, 60,]
          payments_array.push([
            {text: 'No.', alignment: 'left', style: 'main_info',},
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Position', alignment: 'left', style: 'main_info'},
            {text: 'Branch/Department/Designated Area', alignment: 'left', style: 'main_info'},
            {text: 'Date of Employment', alignment: 'left', style: 'main_info'},
            {text: 'Employment Duration', alignment: 'left', style: 'main_info'},
            {text: 'Compensation Rate', alignment: 'left', style: 'main_info'},
            {text: 'SSS#', alignment: 'left', style: 'main_info'},
            {text: 'Pag-ibig#', alignment: 'left', style: 'main_info'},
            {text: 'PH#', alignment: 'left', style: 'main_info'},
            {text: 'TIN#', alignment: 'left', style: 'main_info'},
          ])
          var possii = this.position
          value.forEach(function (item) {
            var a = moment();
            var b = moment(item.date_of_employment, 'YYYY-MM-DD');

            var years = a.diff(b, 'year');
            b.add(years, 'years');

            var months = a.diff(b, 'months');
            b.add(months, 'months');

            var days = a.diff(b, 'days');

            var duration_emp = years + ' years ' + months + ' months ' + days + ' days'
            payments_array.push([
              {
                text: (value
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.last_name + ',' + item.first_name + ' ' + item.middle_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.employee_position.position,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch.branch_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: moment(item.date_of_employment).format('MMMM DD, YYYY'),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: duration_emp,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: possii === 'ADMIN' || possii === 'HUMAN RESOURCE'
                  ? (item.compensation_rate / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sss_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.pag_ibig_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.ph_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.tin_no,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
        }
        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: title,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Category: ',
                  {
                    text: this.category_items[this.category_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.category_id)].category,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: 'Position: ' + this.employee_position_items[this.employee_position_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.employee_position_id)].position,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Type of Employee: ',
                  {
                    text: this.type_of_employee === 1 ? 'Active' :
                      (this.type_of_employee === 2 ? 'Temporary Deactivate' :
                        (this.type_of_employee === 3 ? 'Permanent Deactivated' : '')),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  {
                    text: 'Sorted By: ' + this.sorted_by,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Status: ',
                  {
                    text: this.status,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Length of Service: ',
                  {
                    text: this.duration,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LIST OF EMPLOYEE',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving_data = false
      }
    },
  }
</script>
